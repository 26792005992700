import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';

export function initSentry() {
  init({
    dsn: 'https://93dbe7274d64de4ddfc333470e0d7d45@o4504121051840512.ingest.us.sentry.io/4507727381790720',
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        useEffect,
        useLocation,
        useNavigationType,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
