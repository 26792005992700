import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import InactiveAlert from './InactiveAlert';
import { useGetUser } from '../../../contexts';
import { auth } from '../../../firebase';
import { isAdminUser } from '../../../utils';
import { UserStatus } from '../../../types';

type Props = {
  children: React.ReactNode;
  isAdminRoute?: boolean;
};

export default function ProtectedRoute({
  children,
  isAdminRoute = false,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useGetUser();
  const isAdmin = isAdminUser(user.role);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate('/', { state: { from: location.pathname } });
      return;
    }

    if (isAdminRoute && !isAdmin) {
      navigate('/projects');
    }
  }, [isLoading, isAuthenticated, navigate]);

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setIsLoading(false);
    });

    return subscriber();
  }, []);

  if (isLoading) {
    return <></>;
  }

  const { status } = user;

  if (status === UserStatus.INACTIVE) {
    return <InactiveAlert />;
  }

  return <>{children}</>;
}
