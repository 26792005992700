import { Timestamp } from 'firebase/firestore';

export interface Activity {
  id: string;
  createdAt: Timestamp;
  createdBy: string;
  description: string;
  projectId: string;
  workItemId: string | null;
}

export interface ICompany {
  id: string;
  name: string;
  createdAt: Timestamp;
}

export enum UserRole {
  ADMIN = 'admin',
  OWNER = 'owner',
  PERSONNEL = 'personnel',
}

export interface User {
  uid: string;
  badgeNumber: string;
  canReceiveSms: boolean;
  companyId: string;
  createdAt: Timestamp;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string | null;
  projects: string[];
  role: UserRole;
  status: UserStatus;
}

export interface PersonnelUser extends User {
  trade: LeadTrade;
  rate: PersonnelRate;
  workItems?: string[];
}

export interface AdminUser extends User {
  title: string;
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export interface IProject {
  id: string;
  name: string;
  number: string;
  location: string;
  customer: string;
  startDate: Timestamp;
  endDate: Timestamp;
  createdBy: string;
  createdAt: Timestamp;
  companyId: string;
  status: ItemStatus;
}

export enum ItemStatus {
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
  COMPLETE = 'complete',
  SUSPENDED = 'suspended',
}

export interface IWorkItem {
  id: string;
  companyId: string;
  consumables: string[];
  createdAt: Timestamp;
  createdBy: string;
  materials: string[];
  number: string;
  projectId: string;
  requiredTools: string[];
  status: ItemStatus;
}

export interface ITask {
  id: string;
  companyId: string;
  createdAt: Timestamp;
  createdBy: string;
  name: string;
  number: number;
  leadTrade: LeadTrade;
  hoursAllocated: number;
  progress: number;
  projectId: string;
  status: ItemStatus;
  subtasks: ISubtask[];
  workItemId: string;
}

export interface ISubtask {
  id: string;
  name: string;
  status: ItemStatus;
}

export enum LeadTrade {
  SHIPFITTER = 'shipfitter',
  WELDER = 'welder',
  MACHINIST = 'machinist',
  ELECTRICIAN = 'electrician',
  PIPEFITTER = 'pipefitter',
  SHEETMETAL = 'sheetmetal',
  INSULATOR = 'insulator',
  PAINTER = 'painter',
  RIGGER = 'rigger',
  SCAFFOLDING_BUILDER = 'scaffolding builder',
  FIREWATCH = 'firewatch',
}

export enum PersonnelRate {
  SUPERVISOR = 'supervisor',
  CREW_LEADER = 'crew leader',
  SPECIALIST = 'specialist',
  FIRST_CLASS = 'first class',
  SECOND_CLASS = 'second class',
  THIRD_CLASS = 'third class',
  HANDY_PERSON = 'handy person',
  HELPER = 'helper',
  LABORER = 'laborer',
  APPRENTICE_RATE = 'apprentice',
}
