import { ChakraProvider } from '@chakra-ui/react';
import { PostHogProvider } from 'posthog-js/react';
import { UserProvider, ProjectsProvider } from './contexts';
import Router from './router';
import { theme } from './styles';

export default function App(): JSX.Element {
  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        debug: process.env.NODE_ENV === 'development',
      }}
    >
      <ChakraProvider theme={theme}>
        <UserProvider>
          <ProjectsProvider>
            <Router />
          </ProjectsProvider>
        </UserProvider>
      </ChakraProvider>
    </PostHogProvider>
  );
}
